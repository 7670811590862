//PRODUCTION AWS EC2
export var urlUsers = "https://api.chaider.tejasvaij.com/users/"
export var urlVisualisation = "https://api.chaider.tejasvaij.com/visualisation/"
export var urlChaiderCSV = "https://api.chaider.tejasvaij.com/chaidercsv/"
export var urlAuth = "https://api.chaider.tejasvaij.com/auth/"
export var uriGraphs = "https://api.chaider.tejasvaij.com/graphs/"

//DEVELOPMENT LOCAL MACHINE
// export var urlUsers = "http://localhost:8000/users/"
// export var urlVisualisation = "http://localhost:8000/visualisation/"
// export var urlChaiderCSV = "http://localhost:8000/chaidercsv/"
// export var urlAuth = "http://localhost:8000/auth/"
// export var uriGraphs = "http://localhost:8000/graphs/"