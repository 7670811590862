const MobileDevice = () => {

    return (
        <>
            <div className="d-flex flex-column justify-content-center" style={{ height: "100vh" }}>
                <div className="d-flex justify-content-center">
                    Can't access CHAIDER on mobile devices
                </div>
            </div>
        </>
    );
};

export default MobileDevice;
